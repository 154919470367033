.header {
  --nav-link-padding: 20px;
  position: fixed;
  width: 100%;
  border-top: 1px solid #e7e8e9;
  border-bottom: 1px solid #e7e8e9;
  background-color: #fff;
  font-family: Inter, sans-serif;
  font-weight: 300;
  z-index: 20;
  top: 0;
  left:0;

  [data-backdrop] {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: transparent;
    z-index: 10;
    pointer-events: none;
    opacity: 0.1;

    &[data-backdrop="1"] {
      pointer-events: auto;
    }
  }

  &-container {
    max-width: 1344px;
    padding-right: 4px;
    padding-left: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    margin: 0 auto;

    @media (min-width: 479px) {
      padding-right: 28px;
      padding-left: 28px;
    }

    @media (min-width: 992px) {
      height: 68px;
    }
  }

  &-logo {
    z-index: 20;

    a {
      display: flex;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: -1px;
      right: 0;
      bottom: 0;
      background: var(--white);
      z-index: -1;
      @media (min-width: 992px) {
        content: none;
      }
    }
  }

  &-hamburger {
    cursor: pointer;
    display: flex;
    position: relative;
    z-index: 20;
    height: 52px;
    width: 52px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    &[data-toggle-nav="1"] {
      background: var(--gray);
    }

    @media (min-width: 992px) {
      display: none;
    }
  }

  .nav {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--white);
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 10px;
    border-top: 1px solid #dadada;
    background-color: #fff;
    transition: transform 400ms ease 0s;
    transform: translateY(-70px);
    z-index: 10;

    &[data-show-nav="1"] {
      transform: translateY(100%);
      box-shadow: 0 60px 80px -11px rgba(0, 0, 0, .3)
    }

    @media (min-width: 479px) {
      padding: 10px 14px 20px;
    }

    @media (min-width: 992px) {

      padding: 0;
      border: 0;
      position: relative;
      transform: none !important;
      height: 100%;
      flex-direction: row;
      align-items: center;
      box-shadow: none !important;
      z-index: 10;
    }

    &-link {
      padding: var(--nav-link-padding);
      display: flex;
      align-items: flex-start;
      height: 100%;
      position: relative;
      width: 100%;
      flex-direction: column;
      line-height: 28px;
      color: var(--black);

      @media (min-width: 992px) {
        margin-right: 4px;
        width: auto;
        align-items: center;
      }

      &:hover {
        span:first-child {
          opacity: .6;
        }
      }

      &-btn {
        width: 100%;
        margin-top: 12px;
        background: var(--gray);
        color: var(--black);

        &:hover {
          color: var(--white);
        }

        @media (min-width: 992px) {
          margin-left: 6px;
          margin-top: 0;
          width: auto;
          background-color: var(--black);
          color: var(--white-20);
        }
      }

      &-arrow {
        padding: var(--nav-link-padding) var(--nav-link-padding) 0;

        span:first-child {
          padding-bottom: var(--nav-link-padding);
          @media (min-width: 992px) {
            padding-bottom: 0;
          }
        }

        @media (min-width: 992px) {
          margin-right: 6px;
          padding-right: 40px;
        }
      }

      &-arrow-down {
        width: 10px;
        height: 10px;
        border-left: 3px solid var(--black);
        border-bottom: 3px solid var(--black);
        transform: rotate(-45deg) translate(9px, 6px);
        margin-right: 20px;
        position: absolute;
        top: 29px;
        right: 10px;
      }

      &-dropdown {
        background: var(--white);
        border-radius: 12px;
        box-shadow: 0 7px 20px 0 rgba(0, 0, 0, .21);
        bottom: 0;
        z-index: 10;
        text-align: left;
        left: 0;
        display: none;
        flex-direction: column;
        position: relative;
        transform: none;
        width: calc(100% + var(--nav-link-padding) * 2);

        margin-left: calc(var(--nav-link-padding) * -1);

        @media (min-width: 992px) {
          margin-left: 0;
          position: absolute;
          transform: translateY(100%);
          width: auto;
        }

        &-item {
          padding: 10px 20px;
          line-height: 28px;

          &:hover {
            opacity: .6;
          }
        }
      }

      &[data-dropdown="show"] {
        .nav-link-dropdown {
          display: flex;
        }
      }
    }
  }
}
