html {
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  tab-size: 4;
  word-break: normal;
}

*, :after, :before {
  box-sizing: inherit;
  background-repeat: no-repeat;
}

:after, :before {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  vertical-align: inherit;
}

* {
  margin: 0;
  padding: 0;
}

hr {
  color: inherit;
  height: 0;
  overflow: visible;
}

details, main {
  display: block;
}

summary {
  display: list-item;
}

small {
  font-size: 80%;
}

[hidden] {
  display: none;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

a {
  background-color: #0000;
}

a:active, a:hover {
  outline-width: 0;
}

code, kbd, pre, samp {
  font-family: monospace;
}

pre {
  font-size: 1em;
}

b, strong {
  font-weight: bolder;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  border-color: inherit;
  text-indent: 0;
}

iframe {
  border-style: none;
}

input {
  border-radius: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

textarea {
  resize: vertical;
  overflow: auto;
}

button, input, optgroup, select, textarea {
  font: inherit;
}

optgroup {
  font-weight: 700;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

[role="button"], [type="button"], [type="reset"], [type="submit"], button {
  cursor: pointer;
}

[type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner, button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner, button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="reset"], [type="submit"], button, html [type="button"] {
  -webkit-appearance: button;
}

button, input, select, textarea {
  background-color: #0000;
  border-style: none;
}

a:focus, button:focus, input:focus, select:focus, textarea:focus {
  outline-width: 0;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

select::-ms-expand {
  display: none;
}

select::-ms-value {
  color: currentColor;
}

legend {
  color: inherit;
  max-width: 100%;
  white-space: normal;
  border: 0;
  display: table;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  color: inherit;
  font: inherit;
}

[disabled] {
  cursor: default;
}

img {
  border-style: none;
}

progress {
  vertical-align: baseline;
}

[aria-busy="true"] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled="true"] {
  cursor: default;
}

@font-face {
  font-family: Inter;
  src: url("inter-semibold.6dd8c002.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Inter;
  src: url("inter-regular.da6ea76c.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Inter;
  src: url("inter-regular.da6ea76c.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Playfairdisplay;
  src: url("playfair-semibold.144ab48f.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

:root {
  --black: #141528;
  --black-10: #2c2d3d;
  --black-20: #373849;
  --gray: #e7e8e9;
  --white: #fff;
  --white-20: #f2f5f7;
  --white-30: #dadada;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  background: #f2f5f7;
  padding-top: 80px;
}

select, input, textarea {
  -webkit-appearance: none;
}

.text-center {
  text-align: center;
}

.header {
  --nav-link-padding: 20px;
  width: 100%;
  z-index: 20;
  background-color: #fff;
  border-top: 1px solid #e7e8e9;
  border-bottom: 1px solid #e7e8e9;
  font-family: Inter, sans-serif;
  font-weight: 300;
  position: fixed;
  top: 0;
  left: 0;
}

.header [data-backdrop] {
  height: 100%;
  width: 100%;
  z-index: 10;
  pointer-events: none;
  opacity: .1;
  background-color: #0000;
  position: fixed;
  top: 0;
  left: 0;
}

.header [data-backdrop][data-backdrop="1"] {
  pointer-events: auto;
}

.header-container {
  max-width: 1344px;
  height: 55px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 4px;
  display: flex;
}

@media (min-width: 479px) {
  .header-container {
    padding-left: 28px;
    padding-right: 28px;
  }
}

@media (min-width: 992px) {
  .header-container {
    height: 68px;
  }
}

.header-logo {
  z-index: 20;
}

.header-logo a {
  display: flex;
}

.header-logo:before {
  content: "";
  background: var(--white);
  z-index: -1;
  position: absolute;
  inset: -1px 0 0;
}

@media (min-width: 992px) {
  .header-logo:before {
    content: none;
  }
}

.header-hamburger {
  cursor: pointer;
  z-index: 20;
  height: 52px;
  width: 52px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.header-hamburger[data-toggle-nav="1"] {
  background: var(--gray);
}

@media (min-width: 992px) {
  .header-hamburger {
    display: none;
  }
}

.header .nav {
  background: var(--white);
  z-index: 10;
  background-color: #fff;
  border-top: 1px solid #dadada;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 10px;
  transition: transform .4s;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(-70px);
}

.header .nav[data-show-nav="1"] {
  transform: translateY(100%);
  box-shadow: 0 60px 80px -11px #0000004d;
}

@media (min-width: 479px) {
  .header .nav {
    padding: 10px 14px 20px;
  }
}

@media (min-width: 992px) {
  .header .nav {
    height: 100%;
    z-index: 10;
    border: 0;
    flex-direction: row;
    align-items: center;
    padding: 0;
    position: relative;
    box-shadow: none !important;
    transform: none !important;
  }
}

.header .nav-link {
  padding: var(--nav-link-padding);
  height: 100%;
  width: 100%;
  color: var(--black);
  flex-direction: column;
  align-items: flex-start;
  line-height: 28px;
  display: flex;
  position: relative;
}

@media (min-width: 992px) {
  .header .nav-link {
    width: auto;
    align-items: center;
    margin-right: 4px;
  }
}

.header .nav-link:hover span:first-child {
  opacity: .6;
}

.header .nav-link-btn {
  width: 100%;
  background: var(--gray);
  color: var(--black);
  margin-top: 12px;
}

.header .nav-link-btn:hover {
  color: var(--white);
}

@media (min-width: 992px) {
  .header .nav-link-btn {
    width: auto;
    background-color: var(--black);
    color: var(--white-20);
    margin-top: 0;
    margin-left: 6px;
  }
}

.header .nav-link-arrow {
  padding: var(--nav-link-padding) var(--nav-link-padding) 0;
}

.header .nav-link-arrow span:first-child {
  padding-bottom: var(--nav-link-padding);
}

@media (min-width: 992px) {
  .header .nav-link-arrow span:first-child {
    padding-bottom: 0;
  }

  .header .nav-link-arrow {
    margin-right: 6px;
    padding-right: 40px;
  }
}

.header .nav-link-arrow-down {
  width: 10px;
  height: 10px;
  border-left: 3px solid var(--black);
  border-bottom: 3px solid var(--black);
  margin-right: 20px;
  position: absolute;
  top: 29px;
  right: 10px;
  transform: rotate(-45deg)translate(9px, 6px);
}

.header .nav-link-dropdown {
  background: var(--white);
  z-index: 10;
  text-align: left;
  width: calc(100% + var(--nav-link-padding) * 2);
  margin-left: calc(var(--nav-link-padding) * -1);
  border-radius: 12px;
  flex-direction: column;
  display: none;
  position: relative;
  bottom: 0;
  left: 0;
  transform: none;
  box-shadow: 0 7px 20px #00000036;
}

@media (min-width: 992px) {
  .header .nav-link-dropdown {
    width: auto;
    margin-left: 0;
    position: absolute;
    transform: translateY(100%);
  }
}

.header .nav-link-dropdown-item {
  padding: 10px 20px;
  line-height: 28px;
}

.header .nav-link-dropdown-item:hover {
  opacity: .6;
}

.header .nav-link[data-dropdown="show"] .nav-link-dropdown {
  display: flex;
}

.section {
  background-color: var(--black);
  color: var(--white);
  padding: 140px 0;
  position: relative;
}

@media (min-width: 992px) {
  .section {
    z-index: 1;
  }
}

.section-container {
  width: 100%;
  max-width: 1344px;
  margin: 0 auto;
  padding-left: 28px;
  padding-right: 28px;
  position: relative;
}

.section-grid {
  max-width: 1680px;
  grid-column-gap: 103px;
  grid-row-gap: 84px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  place-items: center;
  margin: 0 auto;
  display: grid;
}

@media (min-width: 992px) {
  .section-grid {
    grid-template-columns: 1fr 1fr;
  }
}

.section-row h3 {
  text-align: center;
  margin-bottom: 50px;
  font-family: Playfairdisplay, sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
}

.section-row-buttons {
  max-width: 100%;
  flex-direction: column;
  gap: 21px;
  margin: 0 auto;
  display: flex;
}

@media (min-width: 479px) {
  .section-row-buttons {
    max-width: 60%;
  }
}

.section .image-row {
  flex-direction: row;
  justify-content: center;
  display: flex;
  position: relative;
}

.section .image-row-top {
  align-items: flex-end;
  padding-bottom: 7px;
}

.section .image-row-bottom {
  align-items: flex-start;
}

.section .image-row-image {
  width: 30%;
  max-width: 180px;
}

.section .image-row-image-l {
  margin-left: 7px;
}

a {
  color: var(--black);
  text-decoration: none;
}

.btn {
  border-radius: 100px;
  padding: 8px 24px 9px;
  font-family: Inter, sans-serif;
  line-height: 28px;
}

.btn-lg {
  padding: 14px 35px;
}

.btn-black {
  background-color: var(--black);
  color: var(--white-20);
}

.btn-black:hover {
  background-color: var(--black-20);
}

.btn-white {
  background: var(--white);
  color: var(--black);
  transition: box-shadow .2s;
}

.btn-white:hover {
  box-shadow: inset 0 0 0 100px #0000001a;
}

.btn-block {
  width: 100%;
}

.footer {
  border-top: 1px solid var(--black-10);
  background-color: var(--black);
  color: var(--white);
  padding-top: 42px;
  padding-bottom: 28px;
}

.footer-container {
  width: 100%;
  max-width: 1344px;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 21px;
  padding-right: 21px;
  display: flex;
  position: relative;
}

@media (min-width: 480px) {
  .footer-container {
    padding-left: 28px;
    padding-right: 28px;
  }
}

.footer-container-top {
  width: 100%;
  border-bottom: 1px solid #ffffff1a;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  padding-bottom: 42px;
  display: flex;
}

@media (min-width: 992px) {
  .footer-container-top {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.footer-container-bottom {
  width: 100%;
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media (min-width: 992px) {
  .footer-container-bottom {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.footer-logo {
  margin-right: 21px;
}

.footer-line {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  line-height: 21px;
  display: flex;
}

.footer-line-top {
  margin-bottom: 28px;
}

@media (min-width: 992px) {
  .footer-line-top {
    margin-bottom: 0;
  }
}

.footer-line-top a {
  color: var(--white);
  margin: 7px 21px 7px 0;
  font-family: Inter, sans-serif;
  transition: opacity .2s;
}

.footer-line-top a:hover {
  opacity: .5;
}

@media (min-width: 768px) {
  .footer-line-top a {
    margin: 7px 28px 7px 0;
  }
}

.footer-line-bottom {
  margin-top: 28px;
}

@media (min-width: 992px) {
  .footer-line-bottom {
    margin-top: 0;
  }
}

.footer-line-bottom a {
  color: var(--white);
  opacity: .5;
  margin: 7px 21px 7px 0;
  font-family: Inter, sans-serif;
  font-size: 14px;
  transition: opacity .2s;
}

.footer-line-bottom a:hover {
  opacity: 1;
}

@media (min-width: 768px) {
  .footer-line-bottom a {
    margin: 7px 28px 7px 0;
  }
}

@media (min-width: 992px) {
  .footer-line-bottom a {
    margin: 0 28px 0 0;
  }
}

.footer-social {
  align-items: center;
  margin: 0 7px;
  display: flex;
}

.footer-social a {
  border: 2px solid var(--white);
  width: 39px;
  height: 39px;
  color: var(--white);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin: 0 7px;
  padding: 7px;
  transition: all .2s;
  display: flex;
}

.footer-social a:hover {
  background-color: var(--white);
  border-color: var(--black);
  color: var(--black);
}

.widget-container {
  max-width: 1344px;
  margin: 0 auto;
  padding: 0 16px;
}

@media (min-width: 479px) {
  .widget-container {
    padding-left: 28px;
    padding-right: 28px;
  }
}

.widget-container h1 {
  text-align: center;
  padding-top: 24px;
  padding-bottom: 36px;
}

@media (min-width: 992px) {
  .widget-container h1 {
    padding-top: 48px;
    padding-bottom: 80px;
  }
}

.tts-search-widget:not(.only-search-bar) {
  height: auto;
}

.tts-search-widget .tts-search-box button.tts-action {
  font-family: Playfairdisplay, sans-serif;
}

.tts-search-widget .tts-search-box .tts-search-job-distance .tts-slider-wrapper > span, .tts-search-widget .tts-search-box .tts-search-job-title > input, .tts-search-widget .tts-search-box .tts-search-job-location > input {
  font-family: Inter, sans-serif;
}

.tts-search-widget .tts-search-box .tts-search-job-location > input, .tts-search-widget .tts-search-box .tts-search-job-title > input {
  width: calc(100% - 16px);
  padding: 4px 8px;
}

.tts-search-widget .tts-result-box {
  margin: 1.5rem -1rem 140px;
}

.tts-application-container {
  position: fixed;
  bottom: 10px;
  right: 10px;
}

@media (min-width: 480px) {
  .tts-application-container {
    bottom: 40px;
    right: 40px;
  }
}

.tts-application-container .tts-application-apply .tts-application-apply-title, .tts-application-container .tts-application-apply .tts-application-apply-text {
  font-family: Inter, sans-serif;
}

.tts-application-container .tts-application-apply-form input[type="text"] {
  font-family: Inter, sans-serif;
  font-size: 14px;
  border-radius: 8px !important;
}

.tts-application-container .tts-application-apply-form input[type="text"]:active, .tts-application-container .tts-application-apply-form input[type="text"]:focus {
  border-radius: 8px !important;
}

.tts-application-container .tts-application-apply-form button {
  font-family: Playfairdisplay, sans-serif;
  border-radius: 50px !important;
}

.tts-application-container .tts-application-apply-form button:hover {
  box-shadow: inset 0 0 0 100px #0000001a;
}

.tts-file-upload + .tts-file-upload-label:hover {
  background-color: #fff3;
}

/*# sourceMappingURL=index.da35005a.css.map */
