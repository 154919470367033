.section {
  background-color: var(--black);
  color: var(--white);
  padding: 140px 0;
  position: relative;
  @media (min-width: 992px) {
    z-index: 1;
  }

  &-container {
    position: relative;
    width: 100%;
    max-width: 1344px;
    margin: 0 auto;
    padding-right: 28px;
    padding-left: 28px;
  }

  &-grid {
    display: grid;
    max-width: 1680px;
    margin: 0 auto;
    justify-items: center;
    align-items: center;
    grid-auto-columns: 1fr;
    grid-column-gap: 103px;
    grid-row-gap: 84px;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    @media (min-width: 992px) {
      grid-template-columns: 1fr 1fr;

    }
  }

  &-row {

    h3 {
      font-family: Playfairdisplay, sans-serif;
      font-size: 40px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 50px;
      line-height: 48px;
    }

    &-buttons {
      max-width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 21px;
      @media (min-width: 479px) {
        max-width: 60%;
      }
    }
  }

  .image-row {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;

    &-top {
      align-items: flex-end;
      padding-bottom: 7px;
    }

    &-bottom {
      align-items: flex-start;
    }


    &-image {
      width: 30%;
      max-width: 180px;

      &-l {
        margin-left: 7px;
      }
    }
  }

}
