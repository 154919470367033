a {
  text-decoration: none;
  color: var(--black);
}

.btn {
  padding: 8px 24px 9px;
  border-radius: 100px;
  font-family: Inter, sans-serif;
  line-height: 28px;

  &-lg{
    padding: 14px 35px;
  }

  &-black {
    background-color: var(--black);
    color: var(--white-20);

    &:hover{
      background-color: var(--black-20);
    }
  }
  &-white {
    background: var(--white);
    color: var(--black);
    transition: box-shadow .2s;

    &:hover{
      box-shadow: inset 0 0 0 100px rgba(0, 0, 0, .1);
    }

  }
  &-block{
    width: 100%;
  }
}