.footer {
  padding-top: 42px;
  padding-bottom: 28px;
  border-top: 1px solid var(--black-10);
  background-color: var(--black);
  color: var(--white);

  &-container {
    position: relative;
    width: 100%;
    max-width: 1344px;
    margin-right: auto;
    margin-left: auto;

    padding-right: 21px;
    padding-left: 21px;

    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 480px) {
      padding-right: 28px;
      padding-left: 28px;
    }

    &-top{
      border-bottom: 1px solid rgb(255 255 255 /10%);
      margin-bottom: 30px;
      padding-bottom: 42px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      @media (min-width: 992px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }
    &-bottom{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      @media (min-width: 992px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  &-logo{
    margin-right: 21px;
  }


  &-line {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    line-height: 21px;

    @media (min-width: 992px) {
    }

    &-top {
      margin-bottom: 28px;
      @media (min-width: 992px) {
        margin-bottom: 0;
      }
      a {
        color: var(--white);
        font-family: Inter, sans-serif;
        margin: 7px 21px 7px 0;
        transition: opacity .2s;

        &:hover{
          opacity: 0.5;
        }

        @media (min-width: 768px) {
          margin: 7px 28px 7px 0;
        }
      }
    }
    &-bottom {
      margin-top: 28px;
      @media (min-width: 992px) {
        margin-top: 0;
      }
      a {
        color: var(--white);
        font-family: Inter, sans-serif;
        margin: 7px 21px 7px 0;
        transition: opacity .2s;
        opacity: 0.5;
        font-size: 14px;

        &:hover{
          opacity: 1;
        }

        @media (min-width: 768px) {
          margin: 7px 28px 7px 0;
        }
        @media (min-width: 992px) {
          margin: 0 28px 0 0;
        }
      }
    }

  }

  &-social {
    display: flex;
    margin: 0 7px;
    align-items: center;

    a {
      border: 2px solid var(--white);
      margin: 0 7px;
      padding: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 39px;
      height: 39px;
      color: var(--white);

      transition: all 0.2s;

      &:hover{
        background-color: var(--white);
        border-color: var(--black);
        color: var(--black);
      }

    }
  }


}