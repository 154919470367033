.widget-container {
  max-width: 1344px;
  padding: 0 16px;
  margin: 0 auto;

  @media (min-width: 479px) {
    padding-right: 28px;
    padding-left: 28px;
  }

  h1 {
    text-align: center;
    padding-top: 24px;
    padding-bottom: 36px;
    @media (min-width: 992px) {
      padding-top: 48px;
      padding-bottom: 80px;
    }
  }
}

.tts-search-widget:not(.only-search-bar) {
  height: auto;
}

.tts-search-widget {
  .tts-search-box {
    button.tts-action {
      font-family: Playfairdisplay, sans-serif;
    }

    .tts-search-job-distance .tts-slider-wrapper > span,
    .tts-search-job-title > input,
    .tts-search-job-location > input {
      font-family: Inter, sans-serif;

    }

    .tts-search-job-location > input,
    .tts-search-job-title > input {
      padding: 4px 8px;
      width: calc(100% - 16px)
    }
  }

  .tts-result-box {
    margin: 1.5rem -1rem 140px;
  }
}

.tts-application-container {
  position: fixed;
  bottom: 10px;
  right: 10px;
  @media (min-width: 480px) {
  bottom: 40px;
  right: 40px;

  }

  .tts-application-apply {
    .tts-application-apply-title {
      //font-family: Playfairdisplay, sans-serif;
      font-family: Inter, sans-serif;

    }

    .tts-application-apply-text {
      font-family: Inter, sans-serif;
    }
  }

  .tts-application-apply-form {
    input[type=text] {
      border-radius: 8px !important;
      font-size: 14px;
      font-family: Inter, sans-serif;

      &:active,
      &:focus {
        border-radius: 8px !important;
      }
    }

    button {
      border-radius: 50px !important;
      font-family: Playfairdisplay, sans-serif;

      &:hover {
        box-shadow: inset 0 0 0 100px rgb(0 0 0 /10%);
      }
    }
  }
}


.tts-file-upload + .tts-file-upload-label:hover {
  background-color: rgb(255 255 255/ 0.2);
}

