@import '~ress';
/* latin */

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/inter-semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/inter-regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/inter-regular.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Playfairdisplay';
  src: url('./assets/fonts/playfair-semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

:root {
  --black: #141528;
  --black-10: #2c2d3d;
  --black-20: #373849;
  --gray: #e7e8e9;
  --white: #fff;
  --white-20: #f2f5f7;
  --white-30: #dadada;
}

body {
  background: #f2f5f7;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  padding-top: 80px;
}

select,
input,
textarea {
  -webkit-appearance: none;
}

.text-center {
  text-align: center;
}

@import "scss/header";
@import "scss/section";
@import "scss/buttons";
@import "scss/footer";
@import "scss/widgets";
